import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SavedPlaylistsTable from './TrackTables/SavedPlaylistsTable';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const SavedPlaylists = (props) => {

    const userAuthenticated = useSelector((state) => state.user && state.user.isAuthenticated);

    const savedPlaylistsCount = useSelector((state) => state.user && state.user.savedPlaylistCount);

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);


    return (
        <Container className="ft-container" maxWidth="md">
            <CssBaseline />
            <Grid className="search-container" container spacing={2}>
                {savedPlaylistsCount === 0 ? (
                    <>
                        <Grid item xs={12}>
                            <h2 style={{ float: 'left' }}>
                                Saved Playlists
                            </h2>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>

                            <Typography variant="h6" color="secondary" component="h2" style={{ textAlign: 'left' }}>
                                You have no saved playlists.
                            </Typography>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12}>
                        <SavedPlaylistsTable headerTitle="My Playlist" apiUrl="tvproscategory?" />
                    </Grid>
                )}
            </Grid>
        </Container >
    )

}
export default (SavedPlaylists);