import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from "react-redux";
import PlaylistActions from './ActionComponents/PlaylistActions';

import {
    //removeFromPlaylist,
    // clearDefaultPlaylist,
    // saveDefaultPlaylist,
    fetchDefaultPlaylist,
    loadLocalPlaylist
} from '../../FlikTraxStore/Actions/userActions';

import Scroll from 'react-scroll-to-element';
import PlayPause from './ActionComponents/PlayPause';
import TrackActions from './ActionComponents/TrackActions';
import ArtistSearch from './ActionComponents/ArtistSearch';
import LoadingBackdrop from '../../Utilities/LoadingBackdrop';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Badge from '@mui/material/Badge';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

var truncate = require('truncate');


function Row(props) {

    const { row } = props;

    return (

        <React.Fragment>
            <TableRow id={'plRow-' + props.trackID} hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">
                    <PlayPause {...props} />
                </TableCell>
                <TableCell component="th" scope="row">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Scroll type="id" element={props.trackID} offset={-75}>
                                <Typography noWrap variant="h6" color="secondary">
                                    <span style={{ marginRight: 10 }}>{truncate(row.track_title, 35)}</span>
                                </Typography>
                            </Scroll>
                            <Typography noWrap variant="subtitle1">
                                <ArtistSearch artist_name={row.artist_name} duration={row.duration} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography style={{ fontSize: "1.00em", color: "#c6dae7" }}>
                                {row.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell align="left">
                    <TrackActions {...props}
                        playlistMode={false}
                        trackTitle={row.track_title}
                        trackParent={row}
                        trackID={props.trackID}
                        fileMp3={row.file_path_compressed}
                        fileUncompressed={row.file_path_uncompressed}
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const MyPlaylistTable = (props) => {

    const dispatch = useDispatch();
    const [playlist, setPlaylist] = useState(null);
    const [playlistLoading, setPlaylistLoading] = useState(false);
    const dataIsLoading = useSelector((state) => state.api && state.api.isLoading);
    const playlistReducer = useSelector((state) => state.user && state.user.defaultPlaylist);
    const defaultPlaylist = playlistReducer;

    const renderPlaylist = () => {
        return (
            defaultPlaylist && defaultPlaylist.map((data, i) => {
                let dataAttrs = {
                    'data-source': data.preview_url,
                    'data-tracktitle': data.track_title,
                    'data-artist': data.artist_name,
                    'data-description': data.description,
                    'data-duration': data.duration,
                    'data-vocaltype': data.vocal_type,
                    'data-trackid': data.id,
                    'data-filecompressed': data.file_path_compressed,
                    'data-fileuncompressed': data.file_path_uncompressed
                };
                return (
                    <Row key={data.id} row={data} dataAttr={dataAttrs} trackID={data.id} />
                );
            })
        );
    }

    useEffect(() => {
        if (!defaultPlaylist || defaultPlaylist.length === 0) {
            dispatch(fetchDefaultPlaylist());
            console.log(defaultPlaylist)
        }
    }, []);

    return (
        <React.Fragment >

            <CssBaseline />
            <LoadingBackdrop open={dataIsLoading} />
            <Grid container spacing={6} >
                <Grid item xs={6}>
                    <h2>
                        <Badge max={15000} badgeContent={playlist && playlist.length} color="info">
                            My Playlist
                        </Badge>
                    </h2>
                </Grid>
                <Grid item xs={6} mt={4} style={{ textAlign: 'right' }}>
                    {defaultPlaylist &&
                        defaultPlaylist.length > 0 && (
                            <PlaylistActions />
                        )}
                </Grid>
            </Grid>
            <TableContainer sx={{ padding: 2 }} component={Paper} >
                <Table aria-label="Latest Tracks">
                    <TableBody>
                        {!dataIsLoading &&
                            (defaultPlaylist === null ||
                                defaultPlaylist === undefined ||
                                defaultPlaylist.length === 0) && (
                                <Typography variant="h6" color="secondary">
                                    Your playlist is empty.  <em>Silence is not golden!</em>
                                </Typography>
                            )}
                        {defaultPlaylist && defaultPlaylist.length > 0 && (
                            renderPlaylist()
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );

}

MyPlaylistTable.propTypes = {

    loadLocalPlaylist: PropTypes.func.isRequired,
    fetchDefaultPlaylist: PropTypes.func.isRequired,
}


const mapStateToProps = state => ({
    api: state.api,
    audioplayer: state.audioplayer,
    user: state.user,
})

export default connect(mapStateToProps,
    {
        fetchDefaultPlaylist,
        loadLocalPlaylist
    })(MyPlaylistTable);
