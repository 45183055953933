import * as React from 'react';
import axios from 'axios';
import { gaPushCompressedDownload, gaPushUncompressedDownload } from '../../../../FlikTraxStore/Actions/googleAnalyticsActions';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';

const Download = (props) => {

    const downloadTrack = require('js-file-download');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userAuthenticated = useSelector((state) => state.user && state.user.isAuthenticated);

    const [mp3Loading, setMp3Loading] = React.useState(false);
    const [waveLoading, setWaveLoading] = React.useState(false);

    const handleDownload = (type) => {
        if (!userAuthenticated) {
            navigate('/login');
        }else{
            let downloadURL = 'https://d3k34l68zzqwxh.cloudfront.net/';
    
            switch (type) {
                case 'mp3':
                    dispatch(gaPushCompressedDownload(props.dataAttr));
                    setMp3Loading(true);
                    downloadURL += props.fileMp3;
                    break;
    
                default:
                    dispatch(gaPushUncompressedDownload(props.dataAttr));
                    setWaveLoading(true);
                    downloadURL += props.fileUncompressed;
            }
    
            let splitUrl = downloadURL.split('/');
            //let downloadTitle = 'PMC-' + splitUrl[5];
            let downloadTitle = splitUrl[5];
    
            axios({
                url: downloadURL,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                downloadTrack(response.data, downloadTitle);
                setMp3Loading(false);
                setWaveLoading(false);
                //props.handleMobileMenuClose();
            });
        }
    }


    return (
        <>
            <React.Fragment>
            <Stack direction={props.mobileMenuOpen === true ? 'column' : 'row'} spacing={1}>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Download Uncompressed"
                    placement="top"
                    arrow
                >
                    <IconButton
                        loading={waveLoading}
                        // sx={{ padding: 1 }}
                        size="small"
                        aria-label="Download Uncompressed"
                        color="warning"
                        onClick={() => handleDownload("uncompressed")}
                    >
                        {waveLoading ? 
                            <CircularProgress style={{maxWidth:15, maxHeight:15}} color="inherit" /> :
                            <DownloadIcon color="warning" />
                        }
                    </IconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Download MP3"
                    placement="top"
                    arrow
                >
                    <IconButton
                        loading={mp3Loading}
                        // sx={{ padding: 1 }}
                        size="small"
                        aria-label="Download MP3"
                        color="info"
                        onClick={() => handleDownload("mp3")}
                    >
                        {mp3Loading ? 
                            <CircularProgress style={{maxWidth:15, maxHeight:15}} color="inherit" /> :
                            <DownloadIcon color="info" />
                        }

                    </IconButton>
                </Tooltip>
            </Stack>    
            </React.Fragment>
        </>


    );
}

export default Download;