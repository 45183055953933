import React from "react";
import { useNavigate, } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Typewriter from 'typewriter-effect';
import { switchMasterMode, switchApiUrl, switchApiPage, fetchApiData, setVocalFilter } from '../../../FlikTraxStore/Actions/apiActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Collapse from '@mui/material/Collapse';
import FilterPills from './FilterPills'
import FilterDrawer from './FilterDrawer';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function encIOSURL(str) {
    return str.replace("”", '"').replace("“", '"').replace("’", "'").replace("‘", "'").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014]/g, '-').replace(/[\u2026]/g, '...');
}

const SearchField = React.forwardRef(
    (
        {
            cancelOnEscape,
            className,
            classes,
            closeIcon,
            disabled,
            onCancelSearch,
            onRequestSearch,
            searchIcon,
            style,
            ...inputProps
        },
        ref
    ) => {
        const inputRef = React.useRef();
        const [value, setValue] = React.useState(localStorage.getItem('currentsearch') ? localStorage.getItem('currentsearch') : inputProps.value);
        const [searcherror, setSearchError] = React.useState(false);
        const [focused, setFocused] = React.useState(false);
        const [searchHistory, setSearchHistory] = React.useState([]);
        const filterCount = useSelector((state) => state.user && state.user.filterCount);
        const dispatch = useDispatch();
        const navigate = useNavigate();

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        };

        const updateSearchHistory = (searchterm) => {
            if (!searchHistory.includes(searchterm)) {
                searchHistory.push(searchterm)
                setSearchHistory(searchHistory)
                localStorage.setItem('searchhistory', JSON.stringify(searchHistory))
            }
        };

        const clearSearchHistory = () => {
            setValue('')
            localStorage.removeItem('currentsearch')
            setSearchHistory([])
            localStorage.removeItem('searchhistory')
        };

        React.useEffect(() => {
            setValue(inputProps.value);
        }, [inputProps.value]);


        const handleBlur = React.useCallback(
            (e) => {
                setFocused(false);
                setValue(e.target.value.trim());
            }
        );

        const handleInput = React.useCallback(

            (e) => {
                setValue(e.target.value);
                if (e.target.value.trim() !== "") {
                    localStorage.removeItem("currentsearch")
                    setSearchError(false)
                } else {
                    setSearchError(true)
                }
            },
        );

        const [filteron, setFilterOn] = React.useState(false);

        const toggleFilter = () => {
            setFilterOn((prev) => !prev);
        };

        const handleRequestSearch = React.useCallback(() => {

            if (value === undefined || value.trim() === '') {
                setSearchError(true)
                return;
            } else {
                setSearchError(false)
            }

            updateSearchHistory(value)

            localStorage.setItem('currentsearch', value);
            localStorage.removeItem('searchType');
            var iOSstr = encIOSURL(value);
            var encsearch = encodeURIComponent(iOSstr);
            var apiUrl = 'search?searchmode=primerchord-filter-search&searchtype=2&searchterm=' + encsearch;

            dispatch(switchMasterMode('search'));
            dispatch(switchApiPage(1));
            dispatch(switchApiUrl(apiUrl));
            dispatch(fetchApiData());
            navigate('/search');
            scrollToTop();
        }, [value, dispatch, navigate]);

        const handleKeyUp = React.useCallback(
            (e) => {
                if (e.charCode === 13 || e.key === "Enter") {
                    handleRequestSearch();
                } else if (
                    cancelOnEscape &&
                    (e.charCode === 27 || e.key === "Escape")
                )
                    if (inputProps.onKeyUp) {
                        inputProps.onKeyUp(e);
                    }
            },
            [handleRequestSearch, inputProps, cancelOnEscape]
        );

        const SearchLabel = () => {

            const typeString = [
                'Modern Hip Hop Beat',
                'Sports Themes',
                'Ambient Serene Mystical',
                'Reflective Tension',
                'Pulsing Electronic EDM',
                'Tom Petty Acoustic',
                'Dark Creepy Halloween'
            ];

            if (searcherror) {
                return ("Please Enter A Search Term")
            } else {

                switch (focused) {
                    case false:
                        return (
                            <Typewriter
                                options={{
                                    strings: typeString,
                                    delay: 60,
                                    deleteSpeed: 10,
                                    pausefor: 300,
                                    skipAddStyles: true,
                                    autoStart: true,
                                    loop: true,
                                    deleteAll: true
                                }}
                            />
                        );

                    default:
                        return ("Search For Anything")
                }
            }
        }


        React.useImperativeHandle(ref, () => ({
            focus: () => {
                inputRef.current.focus();
            },
            blur: () => {
                inputRef.current.blur();
            },
        }));

        const storedHistory = localStorage.getItem('searchhistory') ? JSON.parse(localStorage.getItem('searchhistory')) : [];

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack spacing={2}>
                        <Autocomplete
                            freeSolo
                            //disableCloseOnSelect
                            //disableClearable
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            options={storedHistory.map((option) => option)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...params.InputProps}
                                    //autoFocus
                                    inputRef={inputRef}
                                    onBlur={handleBlur}
                                    value={localStorage.getItem('currentsearch') ? localStorage.getItem('currentsearch'):  value}
                                    onChange={handleInput}
                                    onKeyUp={handleKeyUp}
                                    onFocus={() => setFocused(true)}
                                    fullWidth
                                    disableUnderline
                                    error={searcherror}
                                    //label="Error"
                                    // helperText={currentSearch === null ? "Please enter a search term" : ""}
                                    // color={currentSearch === null ? "warning" : ""}
                                    //focused
                                    InputLabelProps={{
                                        style: { color: '#555', fontStyle: 'italic' },
                                    }}
                                    label={SearchLabel()}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton onClick={handleRequestSearch}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: searchHistory.length > 0 && (
                                                <InputAdornment>
                                                    <Tooltip TransitionComponent={Fade} title="Clear Search History" placement="left" arrow>
                                                    <IconButton onClick={clearSearchHistory}>
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                            }}
                                />
                            )}
                        />
                    </Stack>

                    <Collapse in={true} timeout="auto">
                        <FilterPills toggleFilter={() => toggleFilter()} />
                    </Collapse>

                </Grid>
                <FilterDrawer visibility={filteron} />
            </Grid >

        );
    }
);

SearchField.propTypes = {
    switchMasterMode: PropTypes.func.isRequired,
    switchApiUrl: PropTypes.func.isRequired,
    switchApiPage: PropTypes.func.isRequired,
    fetchApiData: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user
})

export default connect(mapStateToProps,
    {
        switchMasterMode,
        switchApiUrl,
        switchApiPage,
        fetchApiData
    })(SearchField);