import React, { useEffect } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import axios from "axios";
import apiconfig from "../../../../config/api-config.js";
import Carousel from "react-multi-carousel";
import CategoryCard from "./CategoryCard";
import "react-multi-carousel/lib/styles.css";

const CollectionsMultiSider = (props) => {

  const theme = useTheme();

  const [slidercategories, setSliderCategories] = React.useState([]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const slidesToScroll = isSmallScreen ? 2 : 6;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    //if (!slidercategories) {
    let axiosUrl =
      "https://api.fliktrax.com/newreleases?category=primerchord&playlist=[]";
    axios({
      baseURL: axiosUrl,
      method: "get",
      auth: {
        username: apiconfig.api.APIUSERNAME,
        password: apiconfig.api.APIPASSWORD,
      },
      headers: {
        "Content-Type": "application/hal+json",
      },
      //timeout: 2000,
    }).then((response) => {
      setSliderCategories(response.data._embedded.newreleases);
    });
    // }
  }, []);

  return (
    <>
      <Carousel 
        additionalTransfrom={0}
        autoPlay
        autoPlaySpeed={6000}
        infinite
        responsive={responsive}
        pauseOnHover
        slidesToSlide={slidesToScroll}
        swipeable
      >
        {slidercategories.length !== undefined &&
          slidercategories.map((data) => {
            return (
              <div>
                <CategoryCard CardData={data} />
              </div>
            );
          })}
      </Carousel>
    </>
  );
};

export default CollectionsMultiSider;
