import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchApiData, switchMasterMode, switchApiUrl, switchApiPage } from '../../FlikTraxStore/Actions/apiActions';
import { Link } from 'react-router-dom';
import Scroll from 'react-scroll-to-element';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import PlayPause from './ActionComponents/PlayPause';
import TrackActions from './ActionComponents/TrackActions';
import ArtistSearch from './ActionComponents/ArtistSearch';
import MixTable from './MixTable'
import LoadingBackdrop from '../../Utilities/LoadingBackdrop';
import Paginator from './ActionComponents/Paginator';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Fade from '@mui/material/Fade';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
var truncate = require('truncate');

function Row(props) {

    const { row } = props;
    const [open, setOpen] = React.useState(false);

    let variationCount = (row.variation_count > 1) ? row.variation_count - 1 : 0;

    var scrollID = 'row-' + props.trackID;
    var masterID = props.trackID;
    return (

        <React.Fragment>
            <TableRow id={scrollID} hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">
                    <PlayPause {...props} />
                </TableCell>
                <TableCell component="th" scope="row">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Scroll type="id" element={scrollID} offset={-75}>
                                <Typography noWrap variant="h6" color="secondary">
                                    <span style={{ marginRight: 10 }}>{truncate(row.track_title, 35)}</span>
                                    {variationCount > 0 && (
                                        <Tooltip TransitionComponent={Fade} title={(open ? 'Hide ' : 'Show ') + variationCount + ' Alternates/Cutdowns'} placement="top" arrow>
                                            <Chip color="primary" size="small" onClick={() => { setOpen(!open) }} avatar={<IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => { setOpen(!open) }}
                                            >
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>} label={'+ ' + variationCount} />
                                        </Tooltip>
                                    )}
                                </Typography>
                            </Scroll>
                            <Typography noWrap variant="subtitle1">
                                <ArtistSearch artist_name={row.artist_name} duration={row.duration} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography style={{ fontSize: "1.00em", color: "#c6dae7" }}>
                                {row.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell align="left">
                    <TrackActions {...props}
                        playlistMode={true}
                        trackTitle={row.track_title}
                        trackParent={row}
                        trackID={props.trackID}
                        fileMp3={row.file_path_compressed}
                        fileUncompressed={row.file_path_uncompressed}
                    />
                </TableCell>
            </TableRow>
            <TableRow style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit={true}>
                        <Box style={{ borderRadius: 5 }} sx={{ margin: 2, padding: 1, backgroundColor: '#333' }}>
                            <MixTable {...props} parentID={masterID} trackId={row.id} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment >
    );
}

const TrackTable = (props) => {

    const dispatch = useDispatch();

    const [searchData, setSearchData] = React.useState(false);
    const apiData = useSelector((state) => state.api && state.api.apiData);
    const dataIsLoading = useSelector((state) => state.api && state.api.isLoading);
    const searchmode = useSelector((state) => state.api && state.api.masterMode);
    const vocalFilter = useSelector((state) => state.api && state.api.vocalFilter);

    const backToOriginal = () => {
        console.log(localStorage.getItem('searchId'))
        localStorage.setItem('currentsearch', localStorage.getItem('currentsearch'));
        var apiUrl = 'search?searchmode=tvpros&searchtype=2&searchterm=' + localStorage.getItem('searchId');
        dispatch(switchMasterMode('search'));
        dispatch(switchApiPage(1));
        dispatch(switchApiUrl(apiUrl));
        dispatch(fetchApiData());
        localStorage.removeItem('searchId');
        localStorage.removeItem('searchType');
    }

    const clearFilters = () => {
        localStorage.removeItem('currentsearch');
        localStorage.removeItem('searchType');
        window.location.href = "/search";
    }


    const getHeader = () => {
        switch (localStorage.getItem('searchType')) {
            case 'more':
                return (<>Tracks Similar To <Tooltip TransitionComponent={Fade} title="Back To Original Track"><Link style={{ textDecoration: 'underline', color: "#fe6204", marginLeft: 10 }} onClick={() => backToOriginal()} to="#">{localStorage.getItem('currentsearch')}</Link></Tooltip> <span style={{ marginLeft: 25 }}>{action}</span></>);

            default:
                if (dataIsLoading) {
                    return "Searching ..."
                } else {
                    let currentSearch = localStorage.getItem('currentsearch') !== null ? localStorage.getItem('currentsearch') : ""
                    return (props.api && props.api.masterMode === 'search' ? 'TRACKS: ' + currentSearch.toUpperCase() : props.headerTitle);
                }
        }
    }

    const action = (
        <Button variant="outlined" color="secondary" size="small" onClick={() => clearFilters()}>
            Clear
        </Button>
    );

    useEffect(() => {
        if (!apiData) {
            dispatch(switchMasterMode(searchmode));
            dispatch(switchApiUrl(props.apiUrl));
            dispatch(switchApiPage(1));
            dispatch(fetchApiData());
            setSearchData(apiData && apiData._embedded.tvproscategory);
        }

    }, [apiData, dispatch, searchData, props.apiUrl, searchmode]);



    return (

        <React.Fragment >
            <CssBaseline />
            <LoadingBackdrop open={dataIsLoading} />
            <Grid container>
                <Grid item xs={12} p={5}>
                    <div style={{ float: 'right' }}>
                        {vocalFilter && vocalFilter.length > 0 && (
                            <Tooltip TransitionComponent={Fade} title="Clear Vocal Filter">
                                <Chip style={{ cursor: 'pointer' }} color="secondary" label={"Vocal Filter:  " + vocalFilter} onDelete={() => clearFilters()} />
                            </Tooltip>
                        )}
                    </div>
                </Grid>
            </Grid>
            <Grid container>

                <Grid item xs={12}>
                    <h3>
                    {dataIsLoading && ( 
                        <Badge max={15000} badgeContent="" color="primary">
                            SEARCHING ...
                        </Badge>
                    )}
                    {!dataIsLoading && ( 
                        // <Badge max={15000} badgeContent={apiData && apiData.total_items} color="primary">
                        //     {getHeader()}
                        // </Badge>
                        <Typography variant="h6">{getHeader()} <span style={{color:"grey"}}>({apiData && apiData.total_items} RESULTS)</span></Typography>

                    )}
                    </h3>

                </Grid>

                <Grid item xs={12}>
                    {apiData && apiData.total_items > 0 && (
                        <Paginator />
                    )}
                </Grid>
            </Grid >

            <TableContainer style={{ borderStyle: "solid", borderWidth:1,  borderColor:"#9b9b9b" }} sx={{ padding: 2 }} component={Paper} >
                {apiData && apiData.total_items === 0 && (
                    <SnackbarContent
                        style={{
                            color: 'white',
                            backgroundColor: 'black',
                        }}
                        message={
                            'No Results Found. Clearing filters and try again.'
                        }
                        action={action}
                    />

                )}
                <Table aria-label="Latest Tracks" style={{}}>
                    <TableBody >
                        {apiData && props.api.embeddedData[0].map((data) => {
                            //console.log(props.api.embeddedData[0])
                            let dataAttrs = {
                                'data-source': data.preview_url,
                                'data-tracktitle': data.track_title,
                                'data-artist': data.artist_name,
                                'data-description': data.description,
                                'data-duration': data.duration,
                                'data-vocaltype': data.vocal_type,
                                'data-trackid': data.id,
                                'data-filecompressed': data.file_path_compressed,
                                'data-fileuncompressed': data.file_path_uncompressed
                            };
                            return (
                                <Row key={data.id} row={data} dataAttr={dataAttrs} trackID={data.id} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container>
                <Grid item xs={12} >
                    {apiData && apiData.total_items > 0 && (
                        <Paginator />
                    )}
                </Grid>
            </Grid>
        </React.Fragment >
    );

}

const mapStateToProps = state => ({
    api: state.api,
    audioplayer: state.audioplayer,
    user: state.user,
})

export default connect(mapStateToProps,
    {
    })(TrackTable);
