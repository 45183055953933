import * as React from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import {
  switchMasterMode,
  switchApiUrl,
  switchApiPage,
  fetchApiData,
} from "../../../FlikTraxStore/Actions/apiActions";

const ArtistSearch = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const encIOSURL = (str) => {
    return str
      .replace("”", '"')
      .replace("“", '"')
      .replace("’", "'")
      .replace("‘", "'")
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"')
      .replace(/[\u2013\u2014]/g, "-")
      .replace(/[\u2026]/g, "...");
  };
  //const handleRequestSearch = React.useCallback((value) => {
  const handleRequestSearch = (value) => {
    localStorage.setItem("currentsearch", value);
    localStorage.removeItem("searchType");
    var iOSstr = encIOSURL(value);
    var encsearch = encodeURIComponent(iOSstr);
    var apiUrl =
      "search?searchmode=primerchord-filter-search&searchtype=2&searchterm=" +
      encsearch;
    dispatch(switchMasterMode("search"));
    dispatch(switchApiPage(1));
    dispatch(switchApiUrl(apiUrl));
    dispatch(fetchApiData());
    navigate("/search");
    scrollToTop();
  };

  return (
    <a
      className="artist_wrapper"
      style={{ cursor: "pointer" }}
      color="inherit"
      onClick={() => handleRequestSearch(props.artist_name)}
    >
      <Tooltip
        title={"Show all tracks from " + props.artist_name}
        placement="left"
        arrow
      >
        <Typography noWrap variant="subtitle1">
          {props.artist_name} ({props.duration})
        </Typography>
      </Tooltip>
    </a>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    api: state.api,
  };
}

export default connect(mapStateToProps)(ArtistSearch);
