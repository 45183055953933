import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { updateAuthStatus, setAuthUser, updateAuthenticating, smsNotify } from '../FlikTraxStore/Actions/userActions';
import { apiIsLoading } from '../FlikTraxStore/Actions/apiActions';
import LoadingBackdrop from '../Utilities/LoadingBackdrop';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Button, TextField, FormHelperText } from '@mui/material';
import { Formik } from 'formik'
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCompactDisc);

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '55%',
    bgcolor: '#272727',
    border: '1px solid #fff',
    boxShadow: 1,
    p: 3,
}

const RegisterForm = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (props.user && props.user.isAuthenticated === true) {
            navigate('/');
        }
    }, [props.user, navigate]);

    const [form, setForm] = React.useState('register');

    const [loading, setLoading] = React.useState(false);

    const [showPasswords, handleShowPasswords] = React.useState(false);

    const [codeSent, setCodeSent] = React.useState(false);

    const [userAttributes, setUserAttributes] = React.useState({
        username: '',
        password: '',
        attributes: {
            'custom:firstname': '',
            'custom:lastname': '',
            'custom:company': '',
        }
    });


    const renderRegisterForm = () => {

        return (
            <Container className="ft-container" maxWidth="md">
                <LoadingBackdrop open={loading} />
                <Box sx={style} >
                    <img style={{ maxWidth: 225, float: 'right' }} src="/images/logos/primerchord-white-transparent.png" alt="Primerchord Production Music" />
                    <h1>Register</h1>
                    <Formik
                        initialValues={{
                            username: "",
                            firstName: "",
                            lastName: "",
                            company: "",
                            email: "",
                            password: "",
                            confirmPassword: "",

                        }}
                        validationSchema={
                            Yup.object().shape({
                                firstName: Yup.string()
                                    .min(2, 'Must include at least 8 characters.')
                                    .max(50, 'Limit is 50 characters')
                                    .required('First Name is required.'),
                                lastName: Yup.string()
                                    .min(2, 'Must include at least 8 characters.')
                                    .max(50, 'Limit is 50 characters')
                                    .required('Last Name is required.'),
                                company: Yup.string()
                                    .optional(),
                                email: Yup.string()
                                    .email('Must be a valid email.')
                                    .max(255)
                                    .required('Email is required.'),
                                password: Yup.string()
                                    .min(8, 'Must include at least 8 characters.')
                                    .max(25, 'Passwords may not exceed 25 characters.')
                                    .required('Password is required.')
                                    .matches(
                                        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
                                        "Must Contain st least 8 characters, one uppercase and one number."
                                    ),
                                passwordConfirmation: Yup.string()
                                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                            })}

                        onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
                            try {

                                setLoading(true);
                                setSubmitting(true)

                                let authUsername = value.email;
                                let authPassword = value.password;

                                let authAttributes = {
                                    'username': authUsername,
                                    'password': authPassword,
                                    attributes: {
                                        'custom:firstname': value.firstName,
                                        'custom:lastname': value.lastName,
                                        'custom:company': value.company,
                                    }
                                }

                                setUserAttributes(authAttributes);

                                const signUpResponse = await Auth.signUp(authAttributes);

                                setForm('verify');
                                setSubmitting(false)
                                setLoading(false);

                            } catch (err) {
                                console.log(err);
                                setStatus({ success: false })
                                setErrors({ submit: err.message });
                                setSubmitting(false)
                                setLoading(false);
                            }
                        }}
                    >
                        {({ errors,
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            touched,
                        }) => (

                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            fullWidth
                                            helperText={touched.firstName && errors.firstName}
                                            label="First Name"
                                            margin="normal"
                                            name="firstName"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            fullWidth
                                            helperText={touched.lastName && errors.lastName}
                                            label="Last Name"
                                            margin="normal"
                                            name="lastName"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.company && errors.company)}
                                            fullWidth
                                            helperText={touched.company && errors.company}
                                            label="Company (optional)"
                                            margin="normal"
                                            name="company"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email"
                                            margin="normal"
                                            name="email"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.password && errors.password)}
                                            fullWidth
                                            helperText={touched.password && errors.password}
                                            label="Password"
                                            margin="normal"
                                            name="password"
                                            type={showPasswords ? 'text' : 'password'}
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                                            fullWidth
                                            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                                            label="Confirm Password"
                                            margin="normal"
                                            name="passwordConfirmation"
                                            type={showPasswords ? 'text' : 'password'}
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.passwordConfirmation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div style={{ float: 'right' }}>
                                            <Typography variant="span" style={{ color: 'white', fontWeight: 600, marginRight: 5 }}>
                                                Show Passwords
                                            </Typography>
                                            <Switch
                                                onClick={() => handleShowPasswords(!showPasswords)}
                                                color="secondary"
                                                size="small"
                                                labelPlacement="start"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {errors.submit && (
                                            <Box sx={{
                                                mt: 3
                                            }}>
                                                <FormHelperText error>
                                                    <h2>{errors.submit}</h2>
                                                </FormHelperText>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="primary"
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            bgcolor="primary"
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            Register {loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                    <p style={{ marginTop: 25 }}>
                        <h4>Password Policy</h4>
                        Passwords must:
                        <ul>
                            <li>Contain minimum of eight(8) characters</li>
                            <li>Contain at least one(1) number</li>
                            <li>Contain at least one(1) uppercase character</li>
                        </ul>
                    </p>
                    <Divider />
                    <p style={{ marginTop: 25 }}>
                        Already have an account?
                        <Link style={{ textDecoration: 'underline', marginLeft: 10 }} to="/login" >
                            Login here.
                        </Link>
                    </p>
                </Box>
            </Container>
        );
    }

    const renderConfirmForm = () => {

        const resendVerifyCode = () => {
            Auth.resendSignUp(userAttributes.username).then(() => {
                setCodeSent(true);
            }).catch(err => {
                setCodeSent(false);
            });
        }

        return (
            <Container className="ft-verify" maxWidth="md">

                <Box sx={style} >
                    <img style={{ maxWidth: 225, float: 'right' }} src="/images/logos/primerchord-white-transparent.png" alt="Primerchord Production Music" />
                    <h1>Verify Email</h1>
                    Please check your email for the verification code sent to {userAttributes.username}
                    <Formik
                        initialValues={{
                            vcode: ""
                        }}
                        validationSchema={
                            Yup.object().shape({
                                vcode: Yup.string()
                                    .min(4)
                                    .max(255)
                                    .required('Verification code is required.'),
                            })}
                        onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                setLoading(true);
                                setStatus({ success: true })
                                setSubmitting(true);

                                const verifyResponse = await Auth.confirmSignUp(userAttributes.username, value.vcode, { forceAliasCreation: true });
                                if (verifyResponse === "SUCCESS") {
                                    try {
                                        const user = await Auth.signIn(userAttributes.username, userAttributes.password);
                                        props.updateAuthStatus(true);
                                        props.setAuthUser(user);
                                        var msg = "New Primerchord Registration%% Email: " + userAttributes.username;
                                        props.smsNotify("New Primerchord Registration", msg);

                                        navigate('/');

                                    } catch (error) {
                                        console.log(error)
                                    }
                                }
                            } catch (err) {
                                console.log(err);
                                setStatus({ success: false })
                                setErrors({ submit: err.message });
                                setSubmitting(false)
                                setLoading(false);
                            }
                        }}
                    >
                        {({ errors,
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            touched,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <TextField
                                    error={Boolean(touched.vcode && errors.vcode)}
                                    fullWidth
                                    helperText={touched.vcode && errors.vcode}
                                    label="Verification Code"
                                    margin="normal"
                                    name="vcode"
                                    type="text"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.vcode}
                                />
                                {errors.submit && (
                                    <Box sx={{
                                        mt: 3
                                    }}>
                                        <FormHelperText error>
                                            <h2>{errors.submit}</h2>
                                        </FormHelperText>
                                    </Box>
                                )}
                                <Button
                                    variant="primary"
                                    fullWidth
                                    size="large"
                                    color="primary"
                                    bgcolor="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Verify{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                </Button>


                                {errors.submit && (
                                    <React.Fragment>
                                        <Divider style={{ color: 'white', margin: 25 }} />
                                        <Button
                                            onClick={() => resendVerifyCode()}
                                            variant="primary"
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            bgcolor="primary"
                                            disabled={isSubmitting}
                                        >
                                            Resend Code{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                        </Button>

                                        {codeSent && (
                                            <FormHelperText success>
                                                <h2>Code sent successfully</h2>
                                            </FormHelperText>
                                        )}
                                    </React.Fragment>
                                )}

                            </form>
                        )}
                    </Formik>

                </Box>
            </Container>
        );
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    useEffect(() => {

        scrollToTop();

    }, []);

    switch (form) {
        case 'verify':
            return renderConfirmForm();

        case 'resend':
            return;

        default:
            return renderRegisterForm();
    }



}

RegisterForm.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired,
    smsNotify: PropTypes.func.isRequired,
    apiIsLoading: PropTypes.func.isRequired,
}


function mapStateToProps(state, props) {
    return {
        user: state.user,
        api: state.api,
        func: props.func
    };
}

export default connect(mapStateToProps, { updateAuthStatus, setAuthUser, updateAuthenticating, apiIsLoading, smsNotify })(RegisterForm);