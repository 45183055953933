import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import React from 'react';

const renderSocialLinks = (direction) => {

  const moment = require('moment');
  const date = moment(); 
  const year = date.format('YYYY'); 

  return (
    <React.Fragment>
      <Box style={{ float: 'right', marginRight: 50 }}>
        <Typography variant="subtitle2"  component="span">
          &copy; {year} Primerchord Music. All rights reserved.
        </Typography>
      </Box>
    </React.Fragment >
  );

}

const FlikTraxFooter = (props) => {
  return (
    <div>
      <Footer
        backgroundColor='#565656'
        //columnLayout='space-between'
        //style={{paddingRight:100}}
        // columns={[
        //   {
        //     title: '相关资源',
        //     items: [
        //       {
        //         title: 'Ant Design Pro',
        //         url: 'https://pro.ant.design/',
        //         openExternal: true,
        //       },
        //       {
        //         title: 'Ant Design Mobile',
        //         url: 'https://mobile.ant.design/',
        //         openExternal: true,
        //       },
        //       {
        //         title: 'Kitchen',
        //         url: 'https://kitchen.alipay.com/',
        //         description: 'Sketch 工具集',
        //       },
        //     ],
        //   },
        //   {
        //     title: '社区',
        //     items: [
        //       {
        //         title: 'Ant Design Pro',
        //         url: 'https://pro.ant.design/',
        //         openExternal: true,
        //       },
        //       {
        //         title: 'Ant Design Mobile',
        //         url: 'https://mobile.ant.design/',
        //         openExternal: true,
        //       },
        //       {
        //         title: 'Kitchen',
        //         url: 'https://kitchen.alipay.com/',
        //         description: 'Sketch 工具集',
        //       },
        //     ],
        //   },
        //   {
        //     title: '帮助',
        //     items: [
        //       {
        //         title: 'Ant Design Pro',
        //         url: 'https://pro.ant.design/',
        //         openExternal: true,
        //       },
        //       {
        //         title: 'Ant Design Mobile',
        //         url: 'https://mobile.ant.design/',
        //         openExternal: true,
        //       },
        //       {
        //         title: 'Kitchen',
        //         url: 'https://kitchen.alipay.com/',
        //         description: 'Sketch 工具集',
        //       },
        //     ],
        //   },
        //   {
        //     //style: {paddingRight: 75},
        //     icon: (
        //       <img
        //         src="https://gw.alipayobjects.com/zos/rmsportal/nBVXkrFdWHxbZlmMbsaH.svg"
        //         alt="more products"
        //       />
        //     ),
        //     title: '更多产品',
        //     items: [
        //       {
        //         icon: (
        //           <img
        //             src="https://gw.alipayobjects.com/zos/rmsportal/XuVpGqBFxXplzvLjJBZB.svg"
        //             alt="yuque"
        //           />
        //         ),
        //         title: '语雀',
        //         url: 'https://yuque.com',
        //         description: '知识创作与分享工具',
        //         openExternal: true,
        //       },
        //       {
        //         icon: (
        //           <img
        //             src="https://gw.alipayobjects.com/zos/rmsportal/uHocHZfNWZOdsRUonZNr.png"
        //             alt="yuque"
        //           />
        //         ),
        //         title: '云凤蝶',
        //         url: 'https://yunfengdie.com',
        //         description: '中台建站平台',
        //         openExternal: true,
        //       },
        //     ],
        //   },
        // ]}
        bottom={renderSocialLinks()}
      />
    </div>
  );
}

export default FlikTraxFooter;