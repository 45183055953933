import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";

import {
  updateUserVocalType,
  updateUserGenres,
  updateUserMoods,
  updateUserKeywords,
  setFilterCount,
  updateUserBpm
} from '../../../FlikTraxStore/Actions/userActions';

import {
  switchMasterMode,
  switchApiUrl,
  switchApiPage,
  fetchApiData
} from '../../../FlikTraxStore/Actions/apiActions';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import KeyIcon from '@mui/icons-material/Key';
import SpeedIcon from '@mui/icons-material/Speed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSitemap)

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const FilterPills = (props) => {

  const dispatch = useDispatch();

  const currentStoredVocalTypes = useSelector((state) => state.user && state.user.userVocalTypes);
  const currentStoredGenres = useSelector((state) => state.user && state.user.userGenres);
  const currentStoredMoods = useSelector((state) => state.user && state.user.userMoods);
  const currentStoredKeywords = useSelector((state) => state.user && state.user.userKeywords);
  const currentStoredBPM = useSelector((state) => state.user && state.user.userBpm);
  const filterCount = useSelector((state) => state.user && state.user.filterCount);
  //const currentStoredStyles = useSelector((state) => state.user && state.user.userStyles);

  // console.log(currentStoredVocalTypes && currentStoredVocalTypes.hasOwnProperty(0));
  // console.log(currentStoredGenres);
  // console.log(currentStoredMoods);
  // console.log(currentStoredKeywords);
  // console.log(currentStoredBPM);

  // console.log(filterCount)

  const triggerSearch = () => {
    var apiUrl = 'search?searchmode=primerchord-filter-search&searchtype=2&searchterm=' + localStorage.getItem('currentsearch');
    dispatch(switchMasterMode('search'));
    dispatch(switchApiPage(1));
    dispatch(switchApiUrl(apiUrl));
    dispatch(fetchApiData());
  };

  const handleVocalTypeDelete = (chipToDelete) => () => {
    let newVocalTypes = currentStoredVocalTypes.filter(function (obj) {
      return obj.key !== chipToDelete.key;
    });
    dispatch(updateUserVocalType(newVocalTypes));
    dispatch(setFilterCount());
    if (filterCount === 1) {
      window.location.href = "/search";
    }
    triggerSearch();
  };

  const handleBpmDelete = (chipToDelete) => () => {
    dispatch(updateUserBpm([]));
    dispatch(setFilterCount());
    if (filterCount === 1) {
      window.location.href = "/search";
    }
    triggerSearch();
  };

  const handleGenresDelete = (chipToDelete) => () => {
    let newGenres = currentStoredGenres.filter(function (obj) {
      return obj.genres_keys !== chipToDelete.genres_keys;
    });
    dispatch(updateUserGenres(newGenres));
    dispatch(setFilterCount());
    if (filterCount === 1) {
      window.location.href = "/search";
    }
    triggerSearch();
  };

  const handleMoodsDelete = (chipToDelete) => () => {
    let newMoods = currentStoredMoods.filter(function (obj) {
      return obj.moods !== chipToDelete.moods;
    });
    dispatch(updateUserMoods(newMoods));
    dispatch(setFilterCount());
    if (filterCount === 1) {
      window.location.href = "/search";
    }
    triggerSearch();
  };

  const handleKeywordsDelete = (chipToDelete) => () => {
    let newKeywords = currentStoredKeywords.filter(function (obj) {
      return obj.keywords !== chipToDelete.keywords;
    });
    dispatch(updateUserKeywords(newKeywords));
    dispatch(setFilterCount());
    if (filterCount === 1) {
      window.location.href = "/search";
    }
    triggerSearch();
  };

  return (
    <Paper
      sx={{
        //backgroundColor: '#121212',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0,
        m: 0,
      }}
      component="ul"
    >
      {currentStoredVocalTypes && currentStoredVocalTypes.map((data) => {

        return (
          <ListItem key={data.key}>

            <Chip
              variant="filled"
              size="small"
              avatar={<RecordVoiceOverIcon />}
              label={data.label.toUpperCase()}
              onDelete={handleVocalTypeDelete(data)}
            />
          </ListItem>
        );

      })}

      {currentStoredGenres && currentStoredGenres.map((data) => {
        return (
          <ListItem key={data.key}>

            <Chip
              variant="filled"
              size="small"
              avatar={<FontAwesomeIcon style={{ paddingLeft: 4 }} icon={faSitemap} />}
              label={data.genres_keys.toUpperCase()}
              onDelete={handleGenresDelete(data)}
            />
          </ListItem>
        );

      })}

      {currentStoredMoods && currentStoredMoods.map((data) => {

        return (
          <ListItem key={data.moods}>

            <Chip
              variant="filled"
              size="small"
              avatar={<SentimentVerySatisfiedIcon style={{ paddingLeft: 4 }} />}
              label={data.moods.toUpperCase()}
              onDelete={handleMoodsDelete(data)}
            />
          </ListItem>
        );

      })}

      {currentStoredBPM && currentStoredBPM !== undefined &&  currentStoredBPM.length > 0 && (
        <ListItem>
          <Chip
            variant="filled"
            size="small"
            avatar={<SpeedIcon style={{ fontSize: "2em", paddingLeft: 4 }} />}
            label={currentStoredBPM[0] + "-" + currentStoredBPM[1]}
            onDelete={handleBpmDelete([])}
          />
        </ListItem>
      )
      }
      {currentStoredKeywords && currentStoredKeywords.map((data) => {

        return (
          <ListItem key={data.keywords}>

            <Chip
              variant="filled"
              size="small"
              avatar={<KeyIcon style={{ paddingLeft: 4 }} />}
              label={data.keywords.toUpperCase()}
              onDelete={handleKeywordsDelete(data)}
            />
          </ListItem>
        );

      })}

    </Paper>
  );
}

export default (FilterPills);