import {
    FETCH_API_DATA,
    FETCH_FEATURED_PLAYLIST,
    FETCH_MINI_SPORTS_MENU,
    SWITCH_MASTER_MODE,
    SWITCH_API_PAGE,
    SWITCH_API_URL,
    SET_VOCAL_FILTER,
    SET_CURRENT_DEFAULT_GENRES,
    SET_CURRENT_DEFAULT_MOODS,
    SET_CURRENT_DEFAULT_KEYWORDS,
    UPDATE_TRACK_PAGE_TITLE,
    AXIOS_IS_LOADING,
    MODAL_IS_LOADING,
    IS_PLAYLIST_PAGE,
    GET_TRACK_INFO_LINK,
    SET_ACTIVE_MIX_TRACK,
    OPEN_SPLITS,
    CLOSE_SPLITS,
    SET_CURRENT_SEARCH,
    SET_CURRENT_SEARCH_TERM
} from '../Actions/types';

const initialState = {
    apiData: {},
    vocalFilter: '',
    currentGenresFilters: {},
    currentMoodsFilters: {},
    currentKeywordsFilters: {},
    embeddedData: [],
    featuredPlaylist: [],
    apiUrl: 'tvproscategory?',
    masterMode: 'tracks',
    apiPage: 1,
    trackPageTitle: '',
    audioPlayerMode: false,
    isLoading: false,
    modalLoading: false,
    isPlaylist: false,
    trackInfoLink: '',
    activeMixTrack: '',
    currentSearch: null,
    currentSearchTerm: null,
    splitsData: {},
}

const apiReducers = (state = null, action) => {
    switch (action.type) {

        case SET_CURRENT_SEARCH:
            return {
                ...state,
                currentSearch: action.payload,
            }

        case SET_CURRENT_SEARCH_TERM:
            return {
                ...state,
                currentSearchTerm: action.payload,
            }

        case SET_VOCAL_FILTER:
            return {
                ...state,
                vocalFilter: action.payload,
            }

        case SET_CURRENT_DEFAULT_GENRES:
            if (action.payload) {
                for (let i = 0; i < action.payload.length; i++) {
                    delete action.payload[i]['id']
                    delete action.payload[i]['_links']
                }
            }
            return {
                ...state,
                currentGenresFilters: action.payload,
            }

        case SET_CURRENT_DEFAULT_MOODS:
            if (action.payload) {
                for (let i = 0; i < action.payload.length; i++) {
                    delete action.payload[i]['id']
                    delete action.payload[i]['_links']
                }
            }
            return {
                ...state,
                currentMoodsFilters: action.payload,
            }

        case SET_CURRENT_DEFAULT_KEYWORDS:
            if (action.payload) {
                for (let i = 0; i < action.payload.length; i++) {
                    delete action.payload[i]['id']
                    delete action.payload[i]['_links']
                }
            }
            return {
                ...state,
                currentKeywordsFilters: action.payload,
            }

        case FETCH_API_DATA:
            return {
                ...state,
                apiData: action.payload,
                embeddedData: Object.values(action.payload._embedded),
            }

        case FETCH_FEATURED_PLAYLIST:
            return {
                ...state,
                featuredPlaylist: action.payload,
            }

        case FETCH_MINI_SPORTS_MENU:
            return {
                ...state,
                //apiData: action.payload,
                miniSportsMenu: action.payload,
            }

        case SWITCH_MASTER_MODE:
            return {
                ...state,
                masterMode: action.payload
            }

        case SWITCH_API_URL:
            var isPlaylistPage = (action.payload.indexOf("playlist") === 0) ? true : false;
            return {
                ...state,
                apiUrl: action.payload,
                isPlaylist: isPlaylistPage
            }

        case SWITCH_API_PAGE:
            //
            return {
                ...state,
                apiPage: action.payload
            }

        case UPDATE_TRACK_PAGE_TITLE:
            return {
                ...state,
                trackPageTitle: action.payload
            }

        case AXIOS_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }

        case MODAL_IS_LOADING:
            return {
                ...state,
                modalLoading: action.payload.modalLoading
            }


        case IS_PLAYLIST_PAGE:
            return {
                ...state,
                isPlaylist: action.payload
            }

        case GET_TRACK_INFO_LINK:
            return {
                ...state,
                trackInfoLink: action.payload
            }

        case SET_ACTIVE_MIX_TRACK:
            return {
                ...state,
                activeMixTrack: action.payload
            }


        case OPEN_SPLITS:
            return {
                ...state,
                splitsData: action.payload
            }

        case CLOSE_SPLITS:
            return {
                ...state,
                splitsData: action.payload
            }


        default:
            return state;
    }
}

export default apiReducers;


