import {
    FETCH_API_DATA,
    FETCH_FEATURED_PLAYLIST,
    SWITCH_MASTER_MODE,
    SWITCH_API_URL,
    SWITCH_API_PAGE,
    UPDATE_TRACK_PAGE_TITLE,
    IS_PLAYLIST_PAGE,
    SET_ACTIVE_MIX_TRACK,
    OPEN_SPLITS,
    CLOSE_SPLITS,
    SET_CURRENT_SEARCH,
    SET_CURRENT_SEARCH_TERM,
    SET_CURRENT_DEFAULT_GENRES,
    SET_CURRENT_DEFAULT_MOODS,
    SET_CURRENT_DEFAULT_KEYWORDS,
    SET_VOCAL_FILTER
} from './types';

import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import FlikTraxStore from '../FlikTraxStore';
import { updateAutoPlay } from './audioPlayerActions';

function getUserFilters() {
    let userExists = FlikTraxStore.getState().user !== null;
    let genres = userExists && FlikTraxStore.getState().user.userGenres;
    let moods = userExists && FlikTraxStore.getState().user.userMoods;
    let keywords = userExists && FlikTraxStore.getState().user.userKeywords;
    let vocaltype = userExists && FlikTraxStore.getState().user.userVocalTypes;
    let beatsperminute = userExists && FlikTraxStore.getState().user.userBpm;

    let vocalArr = []
    userExists && vocaltype !== undefined && vocaltype.length > 0 && vocaltype.map((data) => {
        vocalArr.push(data.label)
    });

    let genreArr = []
    userExists && genres !== undefined && genres.length > 0 && genres.map((data) => {
        genreArr.push(data.genres_keys)
    });

    let moodsArr = []
    userExists && moods !== undefined && moods.length > 0 && moods.map((data) => {
        moodsArr.push(data.moods)
    });

    let keywordsArr = []
    userExists && keywords !== undefined && keywords.length > 0 && keywords.map((data) => {
        keywordsArr.push(data.keywords)
    });

    // let bpmObj = []
    // userExists && beatsperminute !== undefined && beatsperminute.length > 0 && (
    //     keywordsArr.push(data.keywords)
    // );

    return {
        genres: genreArr,
        styles: [],
        moods: moodsArr,
        keywords: keywordsArr,
        instruments: [],
        vocaltype: vocalArr[0] || [],
        bpm: beatsperminute && beatsperminute.length > 0  ? beatsperminute : false,
        duration: false,
    }
}

export const setCurrentSearch = (trackid) => dispatch => {

    if (trackid === null) {
        dispatch({
            type: SET_CURRENT_SEARCH,
            payload: null
        });
        return;
    }

    axios({
        baseURL: 'https://api.fliktrax.com/track/' + trackid,
        method: 'get',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        headers: {
            "Content-Type": "application/hal+json"
        },
        //timeout: 2000,
    })

        .then(response => {
            dispatch({
                type: SET_CURRENT_SEARCH,
                payload: response.data
            });
        });
}

export const setCurrentSearchTerm = (type, searchterm) => dispatch => {
    dispatch({
        type: SET_CURRENT_SEARCH_TERM,
        payload: { type: type, searchterm: searchterm }
    })
}

export const setVocalFilter = (filter) => dispatch => {
    dispatch({
        type: SET_VOCAL_FILTER,
        payload: filter
    })
}

export const setCurrentDefaultFilters = (filter) => dispatch => {

    var axiosUrl = 'https://api.fliktrax.com/default' + filter;

    axios({
        baseURL: axiosUrl,
        method: 'get',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        headers: {
            "Content-Type": "application/hal+json"
        },
        params: {
            affiliate: 'primerchord'
        },
        timeout: 2000,
    })
        .then(response => {
            switch (filter) {
                case 'keywords':
                    dispatch({
                        type: SET_CURRENT_DEFAULT_KEYWORDS,
                        payload: response.data._embedded.defaultkeywords
                    });
                    break;
                case 'moods':
                    dispatch({
                        type: SET_CURRENT_DEFAULT_MOODS,
                        payload: response.data._embedded.defaultmoods
                    });
                    break;
                case 'genres':
                    dispatch({
                        type: SET_CURRENT_DEFAULT_GENRES,
                        payload: response.data._embedded.defaultgenres
                    });
                    break;
                default:
            }
        })
}

export const fetchApiData = () => {

    var url = FlikTraxStore.getState().api.apiUrl;
    var page = FlikTraxStore.getState().api.apiPage;
    var axiosUrl = apiconfig.api.APIURL + url + '&page=' + page;
    var masterMode = FlikTraxStore.getState().api.masterMode;
    var filters = getUserFilters();

    switch (masterMode) {

        case 'categories':
            axiosUrl = apiconfig.api.APIURL + url + '?page=' + page;
            break;

        case 'trackdetail':
            axiosUrl = url + '&page=' + page;
            break;

        default:
            axiosUrl = apiconfig.api.APIURL + url + '&page=' + page;
    }

    const parseUrl = require('url-parse');
    let currentUrlParsed = parseUrl(axiosUrl)

    let isSearchUrl = false
    if (currentUrlParsed['pathname'] === '/search') {
        isSearchUrl = true;
    }


    return (dispatch) => {

        dispatch(apiIsLoading(true))

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            params: {
                filters: filters,
                affiliate: "primerchord",
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })

            .then(response => {
                dispatch({
                    type: FETCH_API_DATA,
                    payload: response.data
                });

                var oEmbed = Object.values(response.data._embedded);
                var apTracks = oEmbed[0];
                FlikTraxStore.dispatch(updateAutoPlay(apTracks));

                if (isSearchUrl) {

                    let searchTerms = response.data._embedded.search[0].search_terms

                    // --- Return Genres --- //

                    let searchGenres = searchTerms['search_genres_keys']

                    let drawerGenresFilters = []
                    Object.keys(searchGenres).map((sgdata) => {
                        let sgCount = parseInt(searchGenres[sgdata])
                        if (sgCount >= 1) {
                            drawerGenresFilters.push({ 'genres_keys': sgdata, 'count': sgCount })
                        }
                    });

                    FlikTraxStore.dispatch({
                        type: SET_CURRENT_DEFAULT_GENRES,
                        payload: drawerGenresFilters
                    });

                    // --- Return Moods --- //

                    let searchMoods = searchTerms['search_moods']

                    let drawerMoodsFilters = []

                    Object.keys(searchMoods).map((sgdata) => {
                        let smCount = parseInt(searchMoods[sgdata])
                        if (smCount >= 1) {
                            drawerMoodsFilters.push({ 'moods': sgdata, 'count': smCount })
                        }
                    });

                    FlikTraxStore.dispatch({
                        type: SET_CURRENT_DEFAULT_MOODS,
                        payload: drawerMoodsFilters
                    });

                    // --- Return Keywords --- //

                    let searchKeywords = searchTerms['search_keywords']

                    let drawerKeywordsFilters = []

                    Object.keys(searchKeywords).map((sgdata) => {
                        let skCount = parseInt(searchMoods[sgdata])
                        if (skCount >= 1) {
                            drawerKeywordsFilters.push({ 'keywords': sgdata, 'count': skCount })
                        }
                    });

                    FlikTraxStore.dispatch({
                        type: SET_CURRENT_DEFAULT_KEYWORDS,
                        payload: drawerKeywordsFilters
                    });

                }


            })
            .then((response) => dispatch(apiIsLoading(false)))
            .catch((error) => dispatch(apiIsLoading(false)))
    }
};


export const fetchFeaturedPlaylists = () => {
    var playlistIds = FlikTraxStore.getState().user && FlikTraxStore.getState().user.userPlaylist;
    var truncatedPlaylist = playlistIds && Object.values(playlistIds).slice(0, 5).join('","');
    var axiosUrl = 'https://api.fliktrax.com/featuredplaylist';

    return (dispatch) => {

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })

            .then(response => {

                dispatch({
                    type: FETCH_FEATURED_PLAYLIST,
                    payload: response.data._embedded.featuredplaylist
                });
            })
            .then((response) => dispatch(apiIsLoading(false)))
            .catch((error) => dispatch(apiIsLoading(false)))
    }
};


export const switchMasterMode = (mode) => dispatch => {
    dispatch({
        type: SWITCH_MASTER_MODE,
        payload: mode
    })
}

export const switchApiPage = (page) => dispatch => {
    dispatch({
        type: SWITCH_API_PAGE,
        payload: page
    })
}

export const switchApiUrl = (type) => dispatch => {
    dispatch({
        type: SWITCH_API_URL,
        payload: type
    })
}

export const updateTrackPageTitle = (title) => dispatch => {
    dispatch({
        type: UPDATE_TRACK_PAGE_TITLE,
        payload: title
    })
}

export const apiIsLoading = (bool) => {
    return {
        type: "AXIOS_IS_LOADING",
        payload: {
            isLoading: bool
        }
    };
};

export const modalIsLoading = (bool) => {
    return {
        type: "MODAL_IS_LOADING",
        payload: {
            modalLoading: bool
        }
    };
};

export const updateIsPlaylist = (isplaylist) => dispatch => {
    dispatch({
        type: IS_PLAYLIST_PAGE,
        payload: isplaylist
    })
}

export const setActiveMixTrack = (trackid) => dispatch => {
    dispatch({
        type: SET_ACTIVE_MIX_TRACK,
        payload: trackid
    })
}

export const getTrackInfoLink = (trackId, isCustom = false) => {

    var userAttributes = FlikTraxStore.getState().user.user.attributes;
    var userIsPartner = userAttributes['custom:ispartner'];

    return async (dispatch) => {

        var sndData = {}

        switch (userIsPartner) {
            case true:
                var userPartner = userAttributes['custom:partner'];
                sndData = {
                    trackid: trackId,
                    service: 'pdf-service',
                    iscustom: isCustom,
                    partner: userPartner
                }
                break;

            default:
                sndData = {
                    trackid: trackId,
                    service: 'pdf-service',
                    iscustom: isCustom
                }
        }

        await axios({
            baseURL: 'https://api.fliktrax.com/services',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: sndData,
            headers: {
                "Content-Type": "application/hal+json"
            },
        })
            .then(response => {
                let statuscode = response.data.code
                switch (statuscode) {
                    case 200:
                        window.open(response.data.pdfUrl, '_blank');
                        break;

                    default:
                        dispatch(apiIsLoading(true))
                        setTimeout(() => {
                            window.open(response.data.pdfUrl, '_blank');
                            dispatch(apiIsLoading(false))
                        }, 3000);
                }
            })
            .catch((error) => console.log(error))
    }
};

export const openSplits = (trackId) => dispatch => {
    dispatch({
        type: OPEN_SPLITS,
        payload: { open: true, trackid: trackId }
    });
}

export const closeSplits = () => dispatch => {
    dispatch({
        type: CLOSE_SPLITS,
        payload: false
    })
}


export const fetchPublishingData = (trackId) => {

    return async (dispatch) => {

        dispatch(modalIsLoading(true));

        await axios({
            baseURL: 'https://api.fliktrax.com/services',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                trackid: trackId,
                service: 'publishing-splits',
                partner:'cjz-media'
                //iscustom: isCustom
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
        })
            .then(response => {
                dispatch({
                    type: OPEN_SPLITS,
                    payload: response.data
                });

                dispatch(modalIsLoading(false))
            })
            .catch((error) => console.log(error))
    }

}

