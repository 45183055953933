import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
var truncate = require("truncate");

const CategoryCard = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    let destination = "/collection?collectionId=" + props.CardData.slug;
    localStorage.setItem("categoryImage", props.CardData.image);
    localStorage.setItem("categoryTitle", props.CardData.title);
    navigate(destination);
  };

  return (
    <Tooltip
      TransitionComponent={Fade}
      //TransitionProps={{ timeout: 600 }}
      title={props.CardData.title}
      placement="top"
      arrow
    >
      <Card
        sx={{ minWidth: 120, minHeight: 120 }}
        elevation={3}
        onClick={() => handleClick()}
        style={{ backgroundColor: "#565656" }}
      >
        <CardActionArea>
          <CardMedia
            style={{ marginLeft: 5 }}
            paperProps={{ backgroundColor: "#565656" }}
            component="img"
            image={props.CardData.image}
            alt={props.CardData.title}
          />
          {/* <CardContent style={{maxHeight:35}}>
                        <Typography gutterBottom variant="subtitle2" component="span">
                            {truncate(this.props.CardData.title, 15)}
                        </Typography>
                    </CardContent> */}
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

export default CategoryCard;
