import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { switchMasterMode, switchApiUrl, switchApiPage, fetchApiData } from '../../FlikTraxStore/Actions/apiActions';
import { styled, alpha } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import CssBaseline from '@mui/material/CssBaseline';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '35vw',
        },
    },
}));

function encIOSURL(str) {
    return str.replace("”", '"').replace("“", '"').replace("’", "'").replace("‘", "'").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"').replace(/[\u2013\u2014]/g, '-').replace(/[\u2026]/g, '...');
}

function SearchField({props,cancelOnEscape, ...inputProps}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let location = useLocation();

    const [value, setValue] = React.useState(inputProps.value);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleBlur = React.useCallback(
        (e) => {
            setValue(e.target.value.trim());
        }
    );

    const handleInput = React.useCallback(
        (e) => {
            console.log(e.target.value)
            setValue(e.target.value);
        },
    );

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };


    const handleRequestSearch = React.useCallback(() => {
        localStorage.setItem('currentsearch', value);
        localStorage.removeItem('searchType');
        var iOSstr = encIOSURL(value);
        var encsearch = encodeURIComponent(iOSstr);
        var apiUrl = 'search?searchmode=primerchord-filter-search&searchtype=2&searchterm=' + encsearch;
        dispatch(switchMasterMode('search'));
        dispatch(switchApiPage(1));
        dispatch(switchApiUrl(apiUrl));
        dispatch(fetchApiData());
        navigate('/search');
        scrollToTop();
    }, [value, navigate, dispatch]);

    const handleKeyUp = React.useCallback(
        (e) => {
            if (e.charCode === 13 || e.key === "Enter") {
                handleRequestSearch();
            } else if (
                cancelOnEscape &&
                (e.charCode === 27 || e.key === "Escape")
            )
                if (inputProps.onKeyUp) {
                    inputProps.onKeyUp(e);
                }
        },
        [handleRequestSearch, inputProps, cancelOnEscape]
    );

    return (

        <React.Fragment>
            <CssBaseline />
            <Collapse in={( (location.pathname === '/search') )  ? false : true}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        style={{width:300}}
                        placeholder="Search for anything ..."
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleInput}
                        onKeyUp={handleKeyUp}
                        onBlur={handleBlur}
                    />
                </Search>
            </Collapse>
        </React.Fragment>
    );

}

function mapStateToProps(state) {
    return {
        user: state.user,
        api: state.api,
    };
}

export default connect(mapStateToProps)(SearchField);