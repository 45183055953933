import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {
    setCurrentDefaultFilters

} from '../../../FlikTraxStore/Actions/apiActions';

import {
    setFilterCount
} from '../../../FlikTraxStore/Actions/userActions';


const FilterAccordian = lazy(() => import('./FilterAccordian'));

const FilterDrawer = (props) => {

    const dispatch = useDispatch();

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const drawerWidth = isSmallScreen ? '50vw' : '20vw'

    const [state, setState] = React.useState({
        left: false,
    });

    const filterCount = useSelector((state) => state.user && state.user.filterCount);
    const currentGenresFilters = useSelector((state) => state.api && state.api.currentGenresFilters);
    const currentMoodsFilters = useSelector((state) => state.api && state.api.currentMoodsFilters);
    const currentKeywordsFilters = useSelector((state) => state.api && state.api.currentKeywordsFilters);

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        if (!currentGenresFilters) {
            dispatch(setCurrentDefaultFilters('genres'));
            //dispatch(setFilterCount())
        }
        if (!currentMoodsFilters) {
            dispatch(setCurrentDefaultFilters('moods'));
            //dispatch(setFilterCount())
        }
        if (!currentKeywordsFilters) {
            dispatch(setCurrentDefaultFilters('keywords'));
            //dispatch(setFilterCount())
        }

    }, [currentGenresFilters, currentKeywordsFilters, currentMoodsFilters, dispatch]);

    return (
        <div>
            <Button style={{ marginLeft: 20, marginTop: 5 }} component="label" onClick={toggleDrawer('right', true)} variant="contained" startIcon={(filterCount === 0) || (filterCount === undefined) ? <FilterAltOffIcon style={{ color: 'red' }} /> : <FilterAltIcon style={{ color: 'green' }} />}>
                FILTERS {filterCount === 0 || filterCount === undefined ? '' : '(' + filterCount + ')'}
            </Button>

            <SwipeableDrawer
                variant="temporary"
                BackdropProps={{ invisible: true }}
                PaperProps={{
                    sx: {
                        width: [drawerWidth],
                        marginTop: '7vh',
                        backgroundColor: "black",
                        color: "white",
                        borderLeft: 1,
                        overflow: 'scroll',
                        borderColor: '#6e6e6e',
                        height: `calc(100% -50px)`
                    }
                }}
                anchor='right'
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
            >
                <Suspense fallback={
                    <div
                        style={{
                            zIndex: 999,
                            display: 'flex',
                            color: 'white',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '25vh',
                        }}
                    >
                        <Typography variant="subtitle1" gutterBottom style={{color:'grey'}}>
                            Loading Filters ...
                        </Typography>
                    </div>
                }>
                    <FilterAccordian closeDrawer={toggleDrawer('right', false)} />
                </Suspense>
            </SwipeableDrawer>

        </div >
    );
}

export default (FilterDrawer);