import FlikTraxStore from '../FlikTraxStore';
import gtmconfig from '../../../config/google-analytics.js';
import TagManager from 'react-gtm-module';

const pushTag = (eventname, data) => {
    var currentUser = FlikTraxStore.getState().user;
    if (currentUser.user !== undefined) {
        var userID = currentUser.user.username;
        var gtData = [];
        if (userID !== null) {
            var gtmaffiliate = gtmconfig.gtm.affiliate;
            var userAttributes = currentUser.user.attributes;
            var userFirstName = userAttributes['custom:firstname'];
            var userLastName = userAttributes['custom:lastname'];
            var userName = userFirstName + ' ' + userLastName + ' (Primerchord Production Music)';
            var userEmail = currentUser.user.attributes.email;
            gtData.fliktrax_affiliate = gtmaffiliate;
            gtData.trackid = data['data-trackid'];
            gtData.tracktitle = data['data-tracktitle'];
            gtData.artist = data['data-artist'];
            gtData.source = data['data-source'];
            gtData.filecompressed = data['data-filecompressed'];
            gtData.fileuncompressed = data['data-fileuncompressed'];
            gtData.affiliate = gtmaffiliate;
            gtData.userid = userID;
            gtData.username = userName;
            gtData.useremail = userEmail;
            let tagManagerArgs = {
                gtmId: gtmconfig.gtm.code,
                dataLayer: {
                    'event': eventname,
                    ...gtData
                }
            }
            TagManager.initialize(tagManagerArgs);
        }
    }

}

export const gaPushCompressedDownload = (data) => dispatch => {
    pushTag('downloadCompressed', data);
}
export const gaPushUncompressedDownload = (data) => dispatch => {
    pushTag('downloadUncompressed', data);
}

export const gaPushAudioPreview = (data) => dispatch => {
    pushTag('audioPreview', data);
}
