import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const Home = (props) => {


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

    }, []);


    function AboutButton(route, text) {
        return (
            <div>
                <Link to={route}>
                    <Button
                        variant="primary"
                        fullWidth
                        size="large"
                        color="primary"
                        bgcolor="primary"
                    >
                        {text}
                    </Button>
                </Link>
            </div >
        )
    }

    return (
        <Container className="ft-container" maxWidth="md">
            <h1>Custom Music</h1>
            <Divider style={{ marginBottom: 25 }} />
            {/* <h3>Your License To "HitItHard"</h3> */}
            <Box>
                <Parallax
                    bgImage="/images/home/PianoCustom.jpg"
                    bgImageStyle={{ opacity: '.3' }}
                    strength={500}
                >
                    <Box sx={{ flexGrow: 1, p: 4 }}  >
                        <Typography variant="h6" style={{ color: 'white', fontWeight: 600 }}>
                            FlikTrax TV Pros gives our clients immediate access to our award winning team of custom composers. Our diverse collective of over 100+ artists from all over the globe, are available 7 days a week to craft specialized musical works that perfectly compliment your media’s needs.
                            Leave it to us to create a unique sonic experience for every episode/series we collaborate on.

                        </Typography>

                        <Typography variant="h6" style={{ color: 'white', fontWeight: 600 }}>
                            FlikTrax TV Pros not only provides a cohesive musical package but in addition, streamlines quick, “real time” delivery with a variety of integrated solutions. By blurring the line between curated and custom music, FlikTrax renders excellence in audio to your Post Production team, every time.
                            <p> Your show’s next dynamic soundtrack is <Link style={{color: '#fe6204'}}to="/request">just a request away</Link>.</p>
                        </Typography>
                    </Box>
                    {/* {AboutButton('/register', 'Register Now!')} */}
                </Parallax>

                {/* <h3>Music For 'TV Titans'</h3>

                <Parallax
                    bgImage="/images/home/about_fire.jpg"
                    bgImageStyle={{ opacity: '.4' }}
                    strength={500}
                >

                    <Box sx={{ flexGrow: 1, p: 4 }}  >
                        <Typography variant="h6" style={{ color: 'white', fontWeight: 600 }}>
                            FlikTrax TV Pros gives our clients immediate access to our award winning team of custom composers. Our diverse collective of over 100+ artists from all over the globe, are available 7 days a week to craft specialized musical works that perfectly compliment your media’s needs.
                            Leave it to us to create a unique sonic experience for every episode/series we collaborate on.

                        </Typography>

                        <Typography variant="h6" style={{ color: 'white', fontWeight: 600 }}>
                            FlikTrax TV Pros not only provides a cohesive musical package but in addition, streamlines quick, “real time” delivery with a variety of integrated solutions. By blurring the line between curated and custom music, FlikTrax renders excellence in audio to your Post Production team, every time.
                            <p>Your show’s next dynamic soundtrack is just a “Request” away.</p>
                        </Typography>
                    </Box>

                    {AboutButton('/request', 'Request Music')}

                </Parallax> */}
            </Box>
        </Container >
    )
}

export default Home;