import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import SearchField from './TrackTables/SearchComponents/SearchField';
import CollectionsSlider from './TrackTables/ActionComponents/CollectionsMultiSlider';
import TrackTable from './TrackTables/TrackTable';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const Search = (props) => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    useEffect(() => {

        scrollToTop();

    }, []);

    return (
        <Container className="ft-container" maxWidth="md">
            <CssBaseline />
            <Grid className="search-container" container spacing={2}>
                <Grid item xs={12}>
                    <SearchField />
                </Grid>
                <Grid item xs={12}>
                    <h2 style={{ float: 'left' }}>
                        Albums
                    </h2>
                </Grid>
                <Grid className="collections-container" item xs={12}>
                    <CollectionsSlider />
                </Grid>

                <Grid item xs={12}>
                    <TrackTable headerTitle="Latest Tracks" apiUrl="tvproscategory?" />
                </Grid>
            </Grid>

        </Container>
    );

}
export default (Search);
