import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiIsLoading } from "../../FlikTraxStore/Actions/apiActions";
import axios from "axios";
import apiconfig from "../../../config/api-config.js";
import ArtistSearch from "./ActionComponents/ArtistSearch";
import CollectionBreadcrumbs from "./CollectionComponents/CollectionBreadcrumbs.js";
import Scroll from "react-scroll-to-element";
import PlayPause from "./ActionComponents/PlayPause";
import TrackActions from "./ActionComponents/TrackActions";
import MixTable from "./MixTable";
import LoadingBackdrop from "../../Utilities/LoadingBackdrop";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import CssBaseline from "@mui/material/CssBaseline";
import Badge from "@mui/material/Badge";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from '@mui/material/Stack';
var truncate = require("truncate");

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  let variationCount = row.variation_count > 1 ? row.variation_count - 1 : 0;

  var scrollID = "row-" + props.trackID;
  var masterID = props.trackID;
  return (
    <React.Fragment>
      <TableRow id={scrollID} hover sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">
          <PlayPause {...props} />
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Scroll type="id" element={scrollID} offset={-75}>
                <Typography noWrap variant="h6" color="secondary">
                  <span style={{ marginRight: 10 }}>
                    {truncate(row.track_title, 35)}
                  </span>
                  {variationCount > 0 && (
                    <Tooltip
                      TransitionComponent={Fade}
                      title={
                        (open ? "Hide " : "Show ") +
                        variationCount +
                        " Alternates/Cutdowns"
                      }
                      placement="top"
                      arrow
                    >
                      <Chip
                        color="primary"
                        size="small"
                        onClick={() => {
                          setOpen(!open);
                        }}
                        avatar={
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setOpen(!open);
                            }}
                          >
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        }
                        label={"+ " + variationCount}
                      />
                    </Tooltip>
                  )}
                </Typography>
              </Scroll>
              <Typography noWrap variant="subtitle1">
                <ArtistSearch
                  artist_name={row.artist_name}
                  duration={row.duration}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Typography style={{ fontSize: "1.00em", color: "#c6dae7" }}>
                {row.description}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left">
          <TrackActions
            {...props}
            playlistMode={true}
            trackTitle={row.track_title}
            trackParent={row}
            trackID={props.trackID}
            fileMp3={row.file_path_compressed}
            fileUncompressed={row.file_path_uncompressed}
          />
        </TableCell>
      </TableRow>
      <TableRow style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <Box
              style={{ borderRadius: 5 }}
              sx={{ margin: 2, padding: 1, backgroundColor: "#333" }}
            >
              <MixTable {...props} parentID={masterID} trackId={row.id} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CollectionTable = (props) => {
  const UrlCollectionId = new URLSearchParams(window.location.search).get(
    "collectionId"
  );

  const dispatch = useDispatch();

  const [apiData, setCollectionData] = React.useState(false);
  const dataIsLoading = useSelector(
    (state) => state.api && state.api.isLoading
  );

  const masterAlbum =
    apiData &&
    apiData._embedded.newrelease &&
    apiData._embedded.newrelease[0] !== undefined &&
    apiData._embedded.newrelease[0].parent_album;
  const categoryImage = masterAlbum.image;
  const categoryTitle = masterAlbum.name;
  const categoryDescription = masterAlbum.description;
  const categoryAlbum = masterAlbum.album_number;

  useEffect(() => {
    if (!apiData) {
      dispatch(apiIsLoading(true));
      var axiosUrl = "https://api.fliktrax.com/newrelease";

      axios({
        baseURL: axiosUrl,
        method: "get",
        auth: {
          username: apiconfig.api.APIUSERNAME,
          password: apiconfig.api.APIPASSWORD,
        },
        params: {
          newrelease_id: UrlCollectionId,
          page: 1,
          filters: [],
        },
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        setCollectionData(response.data);
        dispatch(apiIsLoading(false));
        props.scrollToTop();
      });
    }
  }, [apiData]);

  return (
    <React.Fragment>
      <CssBaseline />
      <LoadingBackdrop open={dataIsLoading} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CollectionBreadcrumbs categoryTitle={categoryTitle} />
        </Grid>
        <Grid item xs={12} md={4} sx={{ padding: 5 }}>
          {categoryImage && (
            <Avatar
              variant="square"
              alt={props.headerTitle}
              src={categoryImage}
              sx={{
                width: 200,
                height: 200,
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack spacing={2}>
            {/* <Badge
              style={{padding:5}}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              badgeContent={apiData && apiData.total_items}
              color="primary"
            > */}
              <Typography variant="h4" color="secondary">
                {categoryTitle} 
              </Typography>
              {categoryAlbum && <Typography variant="h6">Album: {categoryAlbum}</Typography>}
            {/* </Badge> */}
            <Typography variant="h6">{categoryDescription}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <TableContainer
        style={{ borderStyle: "solid", borderWidth:1,  borderColor:"#9b9b9b" }}
        sx={{ padding: 2 }}
        component={Paper}
      >
        {!masterAlbum && !dataIsLoading && (
          <React.Fragment>Nothing here yet ... Stay Tuned</React.Fragment>
        )}

        <Table aria-label="Latest Tracks">
          <TableBody>
            {apiData &&
              apiData._embedded.newrelease.map((data) => {
                //console.log(props.api.embeddedData[0])
                let dataAttrs = {
                  "data-source": data.preview_url,
                  "data-tracktitle": data.track_title,
                  "data-artist": data.artist_name,
                  "data-description": data.description,
                  "data-duration": data.duration,
                  "data-vocaltype": data.vocal_type,
                  "data-trackid": data.id,
                  "data-filecompressed": data.file_path_compressed,
                  "data-fileuncompressed": data.file_path_uncompressed,
                };
                return (
                  <Row
                    key={data.id}
                    row={data}
                    dataAttr={dataAttrs}
                    trackID={data.id}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default CollectionTable;
