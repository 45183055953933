import * as React from 'react';
import axios from 'axios';
import apiconfig from '../../../../../config/api-config.js';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { saveDefaultPlaylist, updatePlaylistFromLocal, fetchSavedPlaylists } from '../../../../FlikTraxStore/Actions/userActions';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DownloadOutlinedIcon from '@mui/icons-material/BrowserUpdatedOutlined';


const DownloadSavedPlaylistDialog = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const [removeDefault, setRemoveDefault] = React.useState(false);

    const handleDownloadPlaylist= (event) => {
        setLoading(true);
        let axiosUrl = 'https://api.fliktrax.com/userplaylist';
        axios({
            baseURL: axiosUrl,
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                cmd: "download",
                isAdmin: props.user.isAdmin,
                playlistid: props.playlistID,
                template: "cjz-media",
                useremail:props.user.user.attributes.email,
                userid: props.user.user.username
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                console.log(response.data);
                setLoading(true);
                Notify("Download request successful. Check your email for download link.");
                handleClose(); 
                
            });

    };

    const { enqueueSnackbar } = useSnackbar();

    const Notify = (notification) => {
        enqueueSnackbar(notification, { variant: "success" });
    }

    return (
        
                <div>
                    {props.user.isAdmin === true && (
                        <>
                    <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Download This Playlist"
                        placement="top"
                        arrow
                    >
                        <Button size="small" color="warning" style={{ marginRight: 10 }}><DownloadOutlinedIcon onClick={handleClickOpen} /></Button>
                        {/* onClick={handleClickOpen */}
                    </Tooltip>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            <DownloadOutlinedIcon /> Download Playlist "{props.playlistName}"
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                A download link will be sent to email <strong>{props.user.user.attributes.email}</strong>. <br />Click "Download" to continue.  
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleDownloadPlaylist} autoFocus>
                                Download
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </>
                    )}
                </div>
            
    );
}


const mapStateToProps = state => ({
    user: state.user,
})

export default connect(mapStateToProps)(DownloadSavedPlaylistDialog);

