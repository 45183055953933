import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ClassTwoToneIcon from "@mui/icons-material/ClassTwoTone";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import filterconfig from "../../../../config/primerchord-filters.js";
import useMediaQuery from "@mui/material/useMediaQuery";
library.add(faSitemap);

export default function PmcCategoriesFilters(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMoodClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGenresClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCategoryClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("md"));

  const categoryObjects = filterconfig.categories.map((vdata, i) => ({
    title: vdata.title,
    searchterm: vdata.search_term,
  }));

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Production Categories">
          <IconButton
            onClick={handleGenresClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <ClassTwoToneIcon style={{ marginRight: 5 }} />
            <Typography>CATEGORIES</Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            overflow: "visible",
            padding: 4,
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid container spacing={1} columns={12}>
          {categoryObjects.map((data, i) => {
            let st = data.searchterm;
            return (
                <Grid xs={isSmallScreen ? 2 : 6}>
                  <MenuItem
                    onClick={function (event) {
                      props.handleCategoryClick(st);
                      handleClose();
                    }}
                  >
                    <CircleTwoToneIcon
                      style={{ marginRight: 5 }}
                      fontSize="small"
                    />
                    {data.title}
                  </MenuItem>
                </Grid>
            );
          })}
        </Grid>
      </Menu>
    </React.Fragment>
  );
}
