import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';

export default function CollectionBreadcrumbs(props) {
    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            pt={3}
            separator={<NavigateNextIcon fontSize="small" />}
        >
            <Link style={{ textDecoration: 'none' }} color="inherit" href="/albums">
            <LibraryMusicOutlinedIcon style={{ color: 'white', marginRight: 5 }} /> Albums
            </Link>
            <Typography underline="hover" color="text.primary">{props.categoryTitle}</Typography>
        </Breadcrumbs>
    );
}