import React, { useEffect } from 'react';
import BrandLogos from './BrandLogos';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const Home = (props) => {


    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    useEffect(() => {

        scrollToTop();

    }, []);

    return (
        <Container className="ft-container" maxWidth="md">
            <h1>Welcome to TVPros</h1>
            <Divider style={{ marginBottom: 25 }} />
            {/* <h3>Your License To "HitItHard"</h3> */}
            <Box>
                <Parallax
                    bgImage="/images/home/about_fire.jpg"
                    bgImageStyle={{ opacity: '.5' }}
                    strength={500}
                >
                    <Box sx={{ flexGrow: 1, p: 4 }}  >
                        <Typography variant="h6" pb={2} style={{ color: 'white', fontWeight: 600 }}>
                            FlikTrax TV Pros is a world class, exclusive music platform created specifically for Post Production professionals. Our vast, carefully curated library contains the most eclectic, dynamic music options available. 
                        </Typography>

                        <Typography variant="h6" pb={2}  style={{ color: 'white', fontWeight: 600 }}>
                            Intuitive search features allow for quickly locating the exact track(s) needed to enhance your media. By adding hundreds of new tracks weekly, there's always new and vibrant aural options. 
                        </Typography>

                        <Typography variant="h6" pb={2}  style={{ color: 'white', fontWeight: 600 }}>
                            FlikTrax’s Worldwide team of award winning, custom composers are just a “request” away. From full custom music packages to a few key cues, FlikTrax can dial in the exact sound, mood and instrumentation to give your content its perfect sonic identity. 
                        </Typography>

                        <Typography variant="h6" pb={2}  style={{ color: 'white', fontWeight: 600 }}>
                            With placements &amp; compositions in over 1,000 TV/Digital shows, FlikTrax TV Pros are excited to share our passion and experience with your next production. 
                        </Typography>
                    </Box>
                </Parallax>                
            </Box>
            <h1>Curriculum Vitae</h1>
            <Divider style={{ marginBottom: 25 }} />
            <BrandLogos />
        </Container >
    )
}

export default Home;