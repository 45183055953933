import React, { use } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import { CardActionArea } from "@mui/material";
var truncate = require("truncate");

const CollectionCard = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    localStorage.setItem("categoryImage", props.cardData.image);
    localStorage.setItem("categoryTitle", props.cardData.title);
    navigate("/collection?collectionId=" + props.cardData.slug);
  };
  const theme = useTheme();

  return (
    <>
      {props.cardData.visible === true && (
        <Card elevation={0} sx={{ maxWidth: 275, backgroundColor: "#565656" }}>
          <ButtonBase onClick={() => handleClick()}>
            <CardActionArea>
              <CardMedia
                style={{ maxWidth: 265, backgroundColor: "#565656" }}
                component="img"
                width="265"
                height="265"
                image={props.cardData.image}
                alt="Primerchord Production Music"
              />
              <CardContent
                sx={{
                  minHeight: "100px !important",
                  padding: 0,
                  backgroundColor: "#565656",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "1.0em",
                    paddingTop: 2,
                    marginLeft: 1,
                  }}
                  align="left"
                  variant="subtitle2"
                  component="div"
                >
                  {props.cardData.album_number}
                </Typography>
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "1.2em",
                    color: "#ffffff",
                    marginLeft: 1,
                  }}
                  align="left"
                  //gutterBottom
                  variant="h6"
                  component="div"
                >
                  {props.cardData.title}
                </Typography>
                <Typography
                  sx={{ color: "#ffffff", marginLeft: 1 }}
                  paragraph="true"
                  align="left"
                  variant="subtitle1"
                  component="div"
                >
                  {truncate(props.cardData.description, 62)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </ButtonBase>
        </Card>
      )}
    </>
  );
};

export default CollectionCard;
