import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { updateAutoPlay } from '../../FlikTraxStore/Actions/audioPlayerActions';
import PlayPause from './ActionComponents/PlayPause';
import apiconfig from '../../../config/api-config.js';
import TrackActions from './ActionComponents/TrackActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const MixTable = (props) => {

    const [mixData, setMixData] = React.useState(false);
    const [mixLoading, setMixLoading] = React.useState(false);

    const truncate = require('truncate');

    const dispatch = useDispatch();

    const renderMixTable = () => {

        return (
            mixData && mixData.map((mixrow, i) => {
                let dataAttrs = {
                    'data-source': mixrow.preview_url,
                    'data-tracktitle': mixrow.track_title,
                    'data-artist': mixrow.artist_name,
                    'data-description': mixrow.description,
                    'data-duration': mixrow.duration,
                    'data-vocaltype': mixrow.vocal_type,
                    'data-trackid': mixrow.id,
                    'data-filecompressed': mixrow.file_path_compressed,
                    'data-fileuncompressed': mixrow.file_path_uncompressed
                };
                return (
                <TableRow key={mixrow.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell align="left">
                        <PlayPause {...props} dataAttr={dataAttrs} trackID={mixrow.id} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Typography noWrap variant="h6" color="secondary">
                                    <span title={mixrow.track_title} style={{ marginRight: 10 }}>{truncate(mixrow.track_title, 35)}</span>
                                </Typography>
                                <Typography noWrap variant="subtitle1">
                                    {mixrow.artist_name} ({mixrow.duration})
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell>
                        <TrackActions {...props} 
                                      playlistMode={true} 
                                      trackTitle={mixrow.track_title} 
                                      fileMp3={mixrow.file_path_compressed}
                                      fileUncompressed={mixrow.file_path_uncompressed}
                                      trackParent={props.trackParent} 
                                      isMix={true} 
                                      parentID={props.parentID} 
                                      trackID={mixrow.id}  />
                    </TableCell>
                </TableRow>
            )})
        );
    }
    
    useEffect(() => {

        if (!mixData) {

            setMixLoading(true);

            var axiosUrl = 'https://api.fliktrax.com/mixes?track_id=' + props.trackId;

            axios({
                baseURL: axiosUrl,
                method: 'get',
                auth: {
                    username: apiconfig.api.APIUSERNAME,
                    password: apiconfig.api.APIPASSWORD
                },
                headers: {
                    "Content-Type": "application/hal+json"
                },
                //timeout: 2000,
            })
                .then(function (response) {
                    setMixData(response.data._embedded.mixes);
                    setMixLoading(false);
                    //dispatch(updateAutoPlay(response.data._embedded.mixes));
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    }, [mixData, props.trackId]);


    switch (mixLoading) {
        case true:
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 100 }}>
                    <CircularProgress color="info" />
                </Box>
            );

        default:
            return renderMixTable();
    }
}



export default MixTable;